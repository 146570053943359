@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.inputWrapper {
    border-radius: 6px;
    background: rgba(249, 249, 249, 0.20);
    display: flex;
    align-items: center;
}

.input {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: #ffffff;
    flex: 1;
    padding: 13px 14px;

    &::placeholder {
        color: #bbbbbb;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.238px;
    }
}

.suffix {
    color: #B3B3B3;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.238px;
    margin-right: 13px;
}