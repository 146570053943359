@import "../../css/mixins.scss";

.wrapper {
    background-color: transparent;
    padding: 17px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
        padding: 17px 35px;
    }

    @include mobile {
        padding: 17px;
    }
}