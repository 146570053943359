@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.title {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1.82px;
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    gap: 16px;

    @include mobile {
        text-align: center;
        font-size: 20px;
    }
}

.twoCol {
    display: flex;
    gap: 12px;
}

.w60 {
    width: min(600px, 100%);
}

.mb12 {
    margin-bottom: 12px;
}

.submitButtonContainer {
    width: fit-content;

    @include mobile {
        margin: 0px auto;
    }
}

.formWrapper {
    width: 100%;
}

.subtext {
    font-size: 12px;
    color: $light-blue-text;
}

.errorMessage {
    color: rgb(223, 24, 24);
    font-size: 14px;
}

.dark {
    --rmsc-main: rgba(249, 249, 249, 0.20) !important;
    --rmsc-hover: rgb(126, 126, 126) !important;
    --rmsc-selected: #1d1915 !important;
    --rmsc-border: #333333 !important;
    --rmsc-gray: #fff !important;
    --rmsc-bg: rgb(94, 94, 94) !important;
    color: #fff !important;
}
