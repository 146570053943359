$button-bg: #5C5C5C;
$button-bg-hover: #3a3a3a;

$button-bg-opacity: #1c316570;
$button-bg-opacity-hover: #1c316534;

$bg-light: #11A8D8;
$bg-light-hover: #1f93b6;

$light-blue-text: #C0E5FF;

$yellow: #D4C00C;
$green: #64D152;
$red: #d15252;