@import "../../css/mixins.scss";

.cancelButton {
    color: white
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
}

.wrapper {
    color: #ffffff;
}

.title {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1.82px;
    margin-bottom: 42px;

    @include mobile {
        font-size: 20px;
    }
}