@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.wrapper {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    gap: 24px;
    border-radius: 6px;
    background: rgba(249, 249, 249, 0.20);
    width: 100%;
    padding: 12px 14px;

    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.238px;
    position: relative;

    cursor: pointer;

    @include mobile {
        font-size: 12px;
        gap: 8px;
        padding: 10px 6px;
    }
}

.optionContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 4px));
    width: 100%;
    border-radius: 6px;
    z-index: 100;
    max-height: 400px;
    overflow-y: auto;
}

.option {
    width: 100%;
    display: inline-block;
    background-color: rgb(94, 94, 94);
    padding: 8px 14px;
    transition: 0.2s;
    &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    
    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &:hover {
        background-color: rgb(126, 126, 126);
    }
}

.placeholder {
    color: #bbbbbb;
}

.arrowIconOpen {
    transform: rotate(180deg);
}