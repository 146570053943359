@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.content {
    padding: 47px 32px;

    @include mobile {
        padding: 30px 14px;
    }
}

.tableLabel {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.9px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;
    flex: 1;

    @include mobile {
        font-size: 14px;
    }
}

.tableFlex {
    width: 100%;
    display: flex;
    gap: 32px;
}

.tableContainer {
    flex: 1;
}

.tableWide,
.tableLabelWide {
    flex: 1.25;
}

.tabTitleContainer {
    display: flex;
    align-items: center;
    gap: 36px;
    margin-bottom: 30px;
}

.tabTitle {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    padding: 10px 0;

    @include mobile {
        font-size: 10px;
    }
}

.selectedTab {
    color: $bg-light;
    border-bottom: 1px solid $bg-light;
}

.tableSection {
    margin-bottom: 65px;

    @include mobile {
        margin-bottom: 30px;
    }
}

.tableActionButtons {
    display: flex;
    gap: 10px;
    width: 100%;
    margin: 0 auto;
}

.tableActionButton {
    color: #ffffff;
    background-color: $bg-light;
    padding: 3px 12px;
    border-radius: 4px;

    @include mobile {
        font-size: 10px;
    }
}

.green {
    color: $green;
}

.yellow {
    color: $yellow;
}

.red {
    color: $red;
}

.titleFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    &>span {
        flex: 1 1 100%;
    }
}

.address {
    cursor: pointer;
    position: relative;
}

.copyTooltip {
    position: absolute;
    display: block;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
    top: calc(-100% - 4px);
    right: 0;
    transition: 200ms;
}

.copyTooltipShow {
    opacity: 1;
    transition: 200ms;
}