.copyTooltip {
    position: absolute;
    display: block;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
    pointer-events: none;    
    top: -100%;
    right: 0;
    transform: translateX(100%);
}

.address {
    cursor: pointer;
    position: relative;
}