@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.table {
    width: 100%;
    background: rgba(255, 255, 255, 0.00);
    padding: 13px 17px;
}

.th td {
    filter: drop-shadow(0px 0px 6px $bg-light);
    text-transform: uppercase;
    color: $bg-light;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.84px;
    padding-bottom: 16px;
    vertical-align: baseline;
}

.copy {
    font-size: 10px;
    text-transform: none;
    display: block;
    filter: none;
    color: $light-blue-text;
    margin-top: 5px;
    white-space: nowrap;
}

.tr {
    color: $light-blue-text;
    font-size: 14px;
}

.tableWrapper {
    height: 100%;
    border: 1px solid $bg-light;
    box-shadow: 0px 0px 4px 0px $bg-light;
    border-radius: 6px;
    min-height: 120px;
}

.loading {
    height: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}