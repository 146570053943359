@mixin tablet {
    @media (max-width: 870px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 612px) {
        @content;
    }
}