@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.button {
    border-radius: 12px;
    background-color: $button-bg;
    color: #ffffff;
    padding: 13px 35px;
    border: none;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
    &:hover {
        background-color: $button-bg-hover;
    }

    &:disabled {
        opacity: 0.7;
        background-color: $button-bg;
        cursor: not-allowed;
    }
    
    @include tablet {
        font-size: 12px;
        padding: 13px 20px;
    }

    @include mobile {
        font-size: 12px;
        padding: 10px;
    }

}

.submitButton {
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(46, 58, 166, 0.66) 0.03%, rgba(117, 180, 189, 0.66) 99.95%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.238px;
    padding: 13px 35px;
    border: none;

    transition: 0.2s;
    &:hover {
        background-color: $button-bg-hover;
    }

    &:disabled {
        opacity: 0.7;
        background-color: $button-bg;
        cursor: not-allowed;
    }

    @include mobile {
        font-size: 12px;
        padding: 10px 20px;
    }

    @include tablet {
        font-size: 12px;
        padding: 13px 20px;
    }
}