@import "../../css/colors.scss";
@import "../../css/mixins.scss";

.wrapper {
    background-color: #303030;
    position: fixed;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 50%;
    z-index: 100;
    padding: 20px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        width: calc(100% - 40px);
    }
}

.backdrop {
    z-index: 100;
    background-color: #D9D9D9;
    opacity: 0.2;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}

.content {
    width: 100%;
}