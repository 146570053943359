@import "../../css/colors.scss";
@import "../../css/mixins.scss";

@keyframes scaleAnimation {
    0% {
        transform: scale(0) translateY(calc(100% + 12px));
    }
    100% {
        transform: scale(1) translateY(calc(100% + 12px));
    }
}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 32px;

    @include tablet {
        gap: 12px;
    }
}

.contextMenu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(calc(100% + 12px));
    border-radius: 12px;

    animation: scaleAnimation 0.2s;
}

.contextMenuContainer {
    position: relative;
}

.contextMenuItem {
    padding: 12px 32px;
    color: #ffffff;
    
    font-weight: 500;
    font-size: 14;
    width: 100%;
    
    transition: 0.2s;
    background-color: $button-bg;

    &:hover {
        background-color: $button-bg-hover;
    }

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.copyTooltip {
    position: absolute;
    display: block;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    transition: 200ms;
}

.copyTooltipShow {
    opacity: 1;
    transition: 200ms;
}

.relative {
    position: relative;
}

.subtext {
    font-size: 10px;
    font-weight: 400;
    opacity: 0.8;
    white-space: nowrap;
}